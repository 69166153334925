export const App = () => {
  console.log('location', window.location)
  return (
    <>
      <iframe
        src={`https://analytical-space.net/analytical-space/telegram/xfqv9HkOaihkNjJi${window.location.search}`}
      />
    </>
  )
}
